import React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Title from "../../components/title"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../../components/button"
import Products from "../../components/products"
import Workflow from "../../components/workflow"
import FooterCallToAction from "../../components/footer/call-to-action"
import * as styles from "./services.module.scss"

export default function ServicesPage({ data }) {
  return (
    <>
      <Seo
        title="What we do and how we do it at Grycode"
        description="Website performance is what makes or breaks businesses nowadays.
              We can help you build a modern website, or PWA, that will bring
              you customers and and stay on top of your competition."
      />
      <Layout footerChildren={<FooterCallToAction />}>
        <section className={styles.services}>
          <header>
            <Title headingLevel="h4">Services</Title>
            <Title headingLevel="h1">
              We build fast sites & apps in London
            </Title>
            <Title headingLevel="p">
              Website performance is what makes or breaks businesses nowadays.
              We can help you build a modern website, or PWA, that will bring
              you customers and and stay on top of your competition.
            </Title>
            <Button to="/start-project">Get in touch</Button>
          </header>
          <div className={styles.servicesHero}>
            <GatsbyImage image={data.siteStats.sharp.gatsbyImageData} alt="" />
          </div>
        </section>

        <Products subTitle="Services" title="What we do" />

        <Workflow highlight />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    siteStats: file(relativePath: { eq: "site-stats.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
